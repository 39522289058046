import React, { useState, useEffect } from "react";
import "./subSectionOne.scss";
import CardTitleWSelect from '../../../components/CardTitleWSelect/CardTitleWSelect';
//icons
import { formatCurrency } from "../../../helpers/currencyFormatter";
//graphs
import CustomLineChart from "../../../components/MultipleLinesChart/MultipleLinesChart";
import DonutChart from "../../../components/DonutChart/DonutChart";
import HorizontalBarsChart from "../../../components/HorizontalBarsChart/HorizontalBarsChart";
//services
import { getHistoricalPerformanceData } from "../../../services/FinancialServices";
import { getUserTotalAportsData } from "../../../services/FinancialEmployeeService";
//models
import { initialHistoricalPerformanceData } from "../../../models/HistoricalPerformanceModel";
import { initialParticipantSharesModel } from "../../../models/ParticipantSharesModel";

export default function SubSectionOne({ companyId, userOverviewModel, assetDiversification, userSearchData }) {
    
  let brandName = "Syngenta";
  const [interval, setInterval] = useState(200);
  const [historicalPerformanceData, setHistoricalPerformanceData] = useState(initialHistoricalPerformanceData);
  const [participantSharesData, setParticipantSharesData] = useState(initialParticipantSharesModel);

  useEffect(() => {
    if (companyId) {      
      getHistoricalPerformanceData(companyId, "max", userSearchData?.qpsId).then(
        (response) => {
          const historicalPerformanceResponse =
            response?.data?.historicalPerformance;
          if (historicalPerformanceResponse) {
            setHistoricalPerformanceData(historicalPerformanceResponse);
          }
        }
      );

      getUserTotalAportsData(userSearchData?.id).then((response) => {
        const userTotalAportsDataResponse = response?.data;
        if (userTotalAportsDataResponse) {
          setParticipantSharesData(userTotalAportsDataResponse);
        }
      });

    }
  }, [companyId, userSearchData]);


  const handleSelectChange = (value) => {
    getHistoricalPerformanceData(companyId, value).then((response) => {
      const historicalPerformanceResponse =
        response?.data?.historicalPerformance;
      if (historicalPerformanceResponse) {
        setHistoricalPerformanceData(historicalPerformanceResponse);
        if (value === "max") {
          setInterval(300);
        } else if (value === "year") {
          setInterval(50);
        } else if (value === "month") {
          setInterval(20);
        } else if (value === "week") {
          setInterval(10);
        }
      }
    });
  };

  return (
    <div className="">
      <div className="u-flexColumn">
        
        {/* Cards */}
        <div className="u-displayFlex u-marginAs u-justifyEvenly mobile-flexWrap  ">
          <div className="user-wallet-avalue base-data-card seccondary-data-card-bg u-marginAs u-justifyCenter u-Width100">
            <div className="u-flexColumn ">
              <label className="card-label">Valor Actual de la cartera</label>
              <label className="card-price">U$D {formatCurrency(userOverviewModel.totalValue)}.-</label>              
            </div>
          </div>
          <div className="base-data-card tertiary-data-card-side-bg u-marginAs u-justifyCenter u-Width100">
            <div className="u-flexColumn">
              <label className="card-label">Aportes totales</label>
              <label className="card-price">U$D {formatCurrency(userOverviewModel.totalAports)}.-</label>
            </div>
          </div>
          <div className="base-data-card default-data-card-bg u-marginAs u-justifyCenter u-Width100">
            <div className="u-displayFlex gap4">              
              <div className="u-flexColumn">
                <label className="card-label">Aporte desde incial</label>
                <label className="card-price">U$D {formatCurrency(userOverviewModel.initialValue2021)}.-</label>
              </div>
            </div>
          </div>
          <div className="base-data-card default-data-card-bg u-marginAs u-justifyCenter u-Width100">
            <div className="u-displayFlex gap5">              
              <div className="u-flexColumn ">
                <label className="card-label ">% Matching</label>
                <label className="card-price">{userOverviewModel.maching}%</label>
              </div>
            </div>
          </div>
        </div>
        {/* Graph 1 */}
        <div className="u-marginAs">
        <div className="base-data-card default-data-card-bg u-marginAs u-displayFlex areaLine-graph-card">
          <div className='u-flexColumn u-Width100'>
              <div className='u-displayFlex u-alignItems areaLine-card-labels u-justifySpaceBetween'>
                <div className='u-displayFlex'>
                  <h3 className='card-graph-title'>Rendimiento Histórico</h3>
                  <div className='u-marginRm-12 u-alignContentCenter'>
                      <span className='u-marginHm-12 non-mobile-data'>Valor en USD  |</span>
                      <svg width="15" height="7" className='u-marginHs'>
                        <g>
                          <circle cx="3.5" cy="3.5" r="3.5" fill="#0096D7"></circle>
                        </g>
                      </svg>          
                      <span>Rendimiento Acumulado</span>                      
                  </div>
                </div>                
                
                <div className='u-displayFlex u-alignItems'>
                  <div className="non-lower-res-data">
                    <CardTitleWSelect 
                      customTitle={null}
                      onSelectChange={handleSelectChange}/>
                  </div>                  
                </div>                
              </div>              
              <div className='main-chart-container u-marginAm'>
                <CustomLineChart 
                  data={historicalPerformanceData}
                  interval={interval}/>
              </div>              
          </div>
        </div>
        </div>

        
        <div className="u-displayFlex">
          <div className="u-displayFlex u-marginAs u-Width100">
            <div className="base-data-card default-data-card-bg u-marginAs u-displayFlex diversification-donutchart">
              <div className="u-flexColumn u-Width100">
                <div>
                  <h3 className="card-label u-color-title-card-secondary">Diversificación de Activos</h3>
                  {brandName} conservadora
                </div>
                <DonutChart data={assetDiversification} /> 
              </div>
            </div>            
            <div className="base-data-card default-data-card-bg u-marginAs u-displayFlex contributions-barchart ">
              <div className="u-flexColumn u-Width100">
                <div>
                  <h3 className="card-label u-color-title-card-secondary">Aportes Empresa + Participante</h3>
                  <label className="card-label">
                    Aportes totales USD {participantSharesData.aportes.reduce((sum, item) => sum + (item.valueUSD || 0), 0)}
                  </label>
                </div>
                <HorizontalBarsChart data={participantSharesData.aportes}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
