import React from 'react';
import "./subSectionTwo.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CardOneItems } from "../../../components/SubSectionTwo/CardOne/CardOneItems";
import { formatCurrency } from "../../../helpers/currencyFormatter";
//icons
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";
import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import ShowChartOutlinedIcon from '@mui/icons-material/ShowChartOutlined';
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';
//component
import DarkToolTip from "../../../components/ToolTip/DarkToolTip";


export default function SubSectionTwo({ profileData, portfolioMovements }) {
  
  return (
    <div className="u-flexColumn u-Width100 user-plan-section-two u-marginAm">
      <div className=" base-data-card default-data-card-bg ">
        <div className="u-displayFlex u-justifySpaceBetween">
          <label>Perfil</label>
          <MoreVertIcon sx={{ cursor: "pointer" }} />
        </div>

        <div className="u-justifyCenter u-Height100 right-side-card">
          <div className="profile_avatar_bg u-background-bright-blue">
            {" "}
            <img
              src="https://avatar.iran.liara.run/public/5"
              className="profile_avatar "
              alt="profile_avatar"
            />
          </div>
          <div className="u-paddingVs">Bienvenido, {profileData.profileDTO.name}! </div>
          <div className="u-displayFlex u-justifyCenter u-Width70 u-paddingVm">
            Descrubre toda la informacion sobre tu portafolio de inversion de
            pension
          </div>
          <div className="base-data-card  seccondary-data-card-bg u-marginAs u-Width100  u-Height100 ">
            <div className="u-flexColumn u-paddingVl">
              <label className="card-sub-label u-textAlignCenter">
                Perfil de Inversor
              </label>
              <label className="card-label u-textAlignCenter">{profileData.profileDTO.inversorProfile}</label>
            </div>
          </div>
          <div className="u-Width100 u-paddingHl">
            <div className=" u-Width100 u-color-bright-blue u-paddingVl">
              Movimientos de cartera
            </div>
            <div className="card-items u-Width100 u-displayFlex u-Height100 ">
              <CardOneItems
                icon={<LocalAtmOutlinedIcon className="sub-card-icon" />}
                descripcion={"Valor de la Cartera 2021"}
                value={ formatCurrency(portfolioMovements.portfolioUserMovement.initialValue2021) }
                bg={"default-data-card-bg"}
                valueType={"U$D"}
                titleColor ={"u-color-title-card"}
              />
              <CardOneItems
                icon={
                  <AttachMoneyRoundedIcon className="sub-card-icon" />
                }
                descripcion={"Valor a Principio de Año"}
                value={ formatCurrency(portfolioMovements.portfolioUserMovement.beginningOfYearValue) }
                bg={"default-data-card-bg"}
                valueType={"U$D"}
                titleColor ={"u-color-title-card"}
              />
              <CardOneItems
                icon={<ListAltRoundedIcon className="sub-card-icon" />}
                descripcion={"Valor de cuota parte"}
                value={portfolioMovements.portfolioUserMovement.shareValue}
                bg={"default-data-card-bg"}
                valueType={"U$D"}
                titleColor ={"u-color-title-card"}
              />
              <CardOneItems
                icon={<AttachMoneyRoundedIcon className="sub-card-icon" />}
                descripcion={"Valor $ cuota parte"}
                value={formatCurrency(portfolioMovements.portfolioUserMovement.shareValuePeso)}
                bg={"default-data-card-bg"}
                valueType={""}
                titleColor ={"u-color-title-card"}
              />

              <CardOneItems
                icon={<ShowChartOutlinedIcon className="sub-card-icon" />}
                descripcion={"Rendimitnos YTD $"}
                value={formatCurrency(portfolioMovements.portfolioUserMovement.performance.valueUSD)}
                valueType={"U$D"}
                bg={"tertiary-data-card-side-no-bg"}
                titleColor ={"u-color-white"}
                tooltip={
                  <DarkToolTip
                    content={`YTD "Year to Date" Desde Enero este año `}
                    placement={"top"}
                    toolTipIconColor={"white"}
                  />
                }
              />
              <CardOneItems
                icon={<PercentOutlinedIcon className="sub-card-icon" />}
                descripcion={"Rendimiento YTD %"}
                value={portfolioMovements.portfolioUserMovement.performance.percentage}
                valueType={"%"}
                bg={"tertiary-data-card-side-no-bg"}
                titleColor ={"u-color-white"}
                tooltip={
                  <DarkToolTip
                    content={`YTD "Year to Date" Desde Enero este año `}
                    placement={"top"}
                    toolTipIconColor={"white"}
                  />
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
