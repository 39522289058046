export const initialPortfolioUserMovementData = {
    portfolioUserMovement: {
        initialValue2021: "0000",
        beginningOfYearValue: "0000",
        shareValue: "0000",
        shareValuePeso: "0000",
        performance: {
            valueUSD: "0.0",
            percentage: "0.0",
            isPositive: false
        }
    }
}