export const initialParticipantSharesModel = {
    aportes: [
        {
            period: "July 2022",
            valueUSD: 0,
            participantContributions: 0,
            companyContributions: 0
        },
        {
            period: "July 2021",
            valueUSD: 0,
            participantContributions: 0,
            companyContributions: 0
        },
        {
            period: "July 2020",
            valueUSD: 0,
            participantContributions: 0,
            companyContributions: 0
        },
        {
            period: "January 2022",
            valueUSD: 0,
            participantContributions: 0,
            companyContributions: 0
        },
        {
            period: "January 2021",
            valueUSD: 0,
            participantContributions: 0,
            companyContributions: 0
        },
        {
            period: "January 2020",
            valueUSD: 0,
            participantContributions: 0,
            companyContributions: 0
        }
    ]
}