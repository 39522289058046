export const initialBenchmarkModel = {
  dolarMep: {
      historico: [
          {
              fecha: "2024-03-12",
              valor: 1000.00
          },
          {
              fecha: "2024-03-14",
              valor: 1000.00
          },
          {
              fecha: "2024-03-15",
              valor: 1000.00
          },
          {
              fecha: "2024-03-18",
              valor: 1000.00
          },
          {
              fecha: "2024-03-19",
              valor: 1000.00
          },
          {
              fecha: "2024-03-20",
              valor: 1000.00
          },
          {
              fecha: "2024-03-21",
              valor: 1000.00
          },
          {
              fecha: "2024-03-22",
              valor: 1000.00
          },
          {
              fecha: "2024-03-25",
              valor: 1000.00
          },
      ],
      variacionAnual: "00.00%",
      evolucionCartera: []
  },
  inflacion: {
      historico: [
          {
              mes: "2024-03-31",
              inflacion: 0.000
          },
          {
              mes: "2024-04-30",
              inflacion: 0.000
          },
          {
              mes: "2024-06-25",
              inflacion: 0.000
          },
          {
              mes: "2024-07-31",
              inflacion: 0.000
          },
          {
              mes: "2024-08-31",
              inflacion: 0.000
          },
          {
              mes: "2024-09-30",
              inflacion: 0.000
          },
          {
              mes: "2024-10-31",
              inflacion: 0.000
          },
          {
              mes: "2024-11-30",
              inflacion: 0.000
          },
          {
              mes: "2024-12-31",
              inflacion: 0.000
          },
          {
              mes: "2025-01-31",
              inflacion: 0.000
          }
      ],
      variacionAnual: "00.00%"
  }
};