import axiosInstance from "./BaseServiceInstance";
export async function getAllBenchmarkData(id) {
    try {
      const url = `/v1/api/benchmark/all`;
      const response = await axiosInstance.get(url);
      return response;
    } catch (error) {
      console.error('There was an error on the total-aports call', error);
      return null;
    }
}