export function removePrefix(value, prefix = "Syngenta - ") {
    return value.startsWith(prefix) ? value.slice(prefix.length) : value;
}

  export function formatDateString(dateStr) {
    const [month, year] = dateStr.split(' ');
        
    const monthMap = {
        'january': 'Jan.',
        'february': 'Feb.',
        'march': 'Mar.',
        'april': 'Apr.',
        'may': 'May',
        'june': 'Jun.',
        'july': 'Jul.',
        'august': 'Aug.',
        'september': 'Sep.',
        'october': 'Oct.',
        'november': 'Nov.',
        'december': 'Dec.'
    };
        
    const monthAbbrev = monthMap[month.toLowerCase()];
        
    const yearShort = year.slice(-2);
        
    return monthAbbrev && yearShort 
        ? `${monthAbbrev} ${yearShort}`
        : 'Invalid date format';
}