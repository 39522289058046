import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import Box from '@mui/material/Box';
import { formatDateString } from '../../helpers/stringValueFormatter';

export default function HorizontalBarsChart({ data }) {
  const transformedData = data.map(item => ({
    ...item,
    period: formatDateString(item.period),
  }));  

  return (
    <Box sx={{ width: '100%' }}>
      <BarChart
        height={300}
        dataset={transformedData}
        yAxis={[{ 
          scaleType: 'band', 
          dataKey: 'period', 
          disableLine: true, 
          disableTicks: true 
        }]}
        xAxis={[{ 
          disableLine: true, 
          disableTicks: true 
        }]}
        series={[
          { 
            dataKey: 'participantContributions', 
            layout: 'vertical', 
            stack: 'stack', 
            color: '#A1E3CB',
            label: 'Participant Contributions'
          },
          { 
            dataKey: 'companyContributions', 
            layout: 'vertical', 
            stack: 'stack', 
            color: '#85D1EF',
            label: 'Company Contributions'
          },
        ]}
        layout="horizontal"
        grid={{ vertical: true }}
        tooltip={{ trigger: 'item' }}
        legend={{ hidden: true }} 
        {...chartSettingsV}
      />
    </Box>
  );
}

const chartSettingsV = {
  outerRadius: 100,
  grid: { horizontal: true },
};