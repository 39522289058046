import axiosInstance from "./BaseServiceInstance";

export async function getUserTotalAportsData(id) {
    try {
      const url = `/v1/api/financial-user/total-aports?id=${id}`;
      const response = await axiosInstance.get(url);
      return response;
    } catch (error) {
      console.error('There was an error on the total-aports call', error);
      return null;
    }
}

export async function getUserProfileData(id) {
    try {
      const url = `/v1/api/financial-user/profile-data?id=${id}`;
      const response = await axiosInstance.get(url);
      return response;
    } catch (error) {
      console.error('There was an error on the profile-data call', error);
      return null;
    }
}

export async function getUserPortfolioMovementData(id) {
    try {
      const url = `/v1/api/financial-user/portfolio-movement?id=${id}`;
      const response = await axiosInstance.get(url);
      return response;
    } catch (error) {
      console.error('There was an error on the portfolio-movement call', error);
      return null;
    }
}

export async function getUserOverviewData(id) {
    try {
      const url = `/v1/api/financial-user/overview?id=${id}`;
      const response = await axiosInstance.get(url);
      return response;
    } catch (error) {
      console.error('There was an error on the fin overview call', error);
      return null;
    }
}

export async function getUserHistoricalPerformanceData(id) {
    try {
      const url = `/v1/api/financial-user/historical-performance?id=${id}`;
      const response = await axiosInstance.get(url);
      return response;
    } catch (error) {
      console.error('There was an error on the historical-performance call', error);
      return null;
    }
}

export async function getUserAssetDiversificationData(id) {
    try {
      const url = `/v1/api/financial-user/asset-diversification?id=${id}`;
      const response = await axiosInstance.get(url);
      return response;
    } catch (error) {
      console.error('There was an error on the asset-diversification call', error);
      return null;
    }
}
