import React, { useState, useEffect } from "react";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import CardTitleWSelect from "../../components/CardTitleWSelect/CardTitleWSelect";
import AreaAndLineBenchChart from "../../components/AreaAndLineBenchChart/AreaAndLineBenchChart";
import VerticalBarsAndLineChart from "../../components/VerticalBarsAndLineChart/VerticalBarsAndLineChart";
import { useTranslation } from "react-i18next";
import { initialBenchmarkModel } from "../../models/BenchmarkDataModel";
import { getAllBenchmarkData } from "../../services/BenchmarkService";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import "./MainBenchmark.scss";
export default function MainBenchmark({ companyId }) {
    const { t } = useTranslation();
    
    // eslint-disable-next-line
    const [benchmarkData, setBenchmarkData] = useState(
      initialBenchmarkModel
    );
    const [interval, setInterval] = useState(20);

    const handleSelectChange = (value) => {          
          if (benchmarkData) {            
            if (value === "max") {
              setInterval(300);
            } else if (value === "year") {
              setInterval(50);
            } else if (value === "month") {
              setInterval(20);
            } else if (value === "week") {
              setInterval(10);
            }
          }        
      };

    useEffect(() => {
      getAllBenchmarkData().then((response) => {
        response.data ? setBenchmarkData(response.data) : console.log("no response");
        console.log('response from benchmark', response)
      });
      }, []);

  return (
    <div className="u-displayGrid main-benchmark">
      <div className="usuarios-header display_column ">
        <HeaderBar
          headerTitle={"Benchmark"}
          headerTitleInfo={"Resumen"}
        />
      </div>

      <div className="u-flexColumn wrap ">
        <div className="blue-gradient-bg u-Width95 u-marginLm benchmark-tag">
          <h2>
            Dolar Bolsa (MEP)
          </h2>          
        </div>
        <div className="base-data-card default-data-card-bg u-displayFlex u-Width95 u-marginAm">
          <div className="u-flexColumn u-Width100">
            <div className="u-displayFlex u-Width100 u-justifySpaceBetween ">
              <div className="u-displayInlineBlock u-Width100">
                <h3 className="card-graph-title">
                  {t("Dashboard.sectionOne.Rendimiento Histórico")}
                </h3>
                <span className="u-marginHm-12 color-grayed-subtitle benchmark-card-label">
                  {t("Dashboard.sectionOne.Valor en")} USD |
                </span>
                <div className="u-displayInlineBlock u-alignItems">
                    <svg width="15" height="7">
                        <g>
                          <circle cx="3.5" cy="3.5" r="3.5" fill="#0096D7"></circle>
                        </g>
                      </svg>
                      <span className="benchmark-card-label">Dolar Bolsa (MEP)</span>
                      <svg width="15" height="7" className="u-marginLm">
                        <g>
                          <circle cx="3.5" cy="3.5" r="3.5" fill="#4AA785"></circle>
                        </g>
                      </svg>
                    <span className="benchmark-card-label">Evolución de Cartera</span>
                </div>                
              </div>          
              <div className="benchmark-select-tags">
                {/* <CardTitleWSelect
                  customTitle={null}
                  onSelectChange={handleSelectChange}
                /> */}
              </div>
              
            </div>            

            <div className="main-chart-container ">
              <AreaAndLineBenchChart
                data={benchmarkData.dolarMep.historico}
                interval={interval}
              />
            </div>
          </div>
        </div>
        <div className="u-displayFlex u-Width95 u-marginBm u-marginLm">
          <div className="base-data-card default-data-card-bg u-Width100">
            <div className="u-flexColumn">
              <h3 className="u-color-title-card card-graph-title">Periodo de 1 AÑO - Dolar Bolsa (MEP) </h3>
              <label className="card-price">
                {benchmarkData.dolarMep.variacionAnual}
                  {true ? (
                    <span className="u-color-success">
                      <TrendingUpIcon />
                    </span>
                  ) : (
                    <span className="u-color-danger">
                      <TrendingDownIcon />
                    </span>
                  )}
              </label>
            </div>            
          </div>
          <div className="base-data-card default-data-card-bg u-marginLm u-Width100">
            <div className="u-flexColumn">
              <h3 className="u-color-title-card card-graph-title">Periodo de 1 AÑO - Evolución de Cartera </h3>            
              <label className="card-price">
                  14.5%
                  {true ? (
                    <span className="u-color-success">
                      <TrendingUpIcon />
                    </span>
                  ) : (
                    <span className="u-color-danger">
                      <TrendingDownIcon />
                    </span>
                  )}
              </label>
            </div>            
          </div>
        </div>
        <div className="blue-gradient-bg u-Width95 u-marginLm benchmark-tag">
          <h2>
            Inflación de EEUU
          </h2>          
        </div>
        <div className="base-data-card default-data-card-bg u-displayFlex u-Width95 u-marginAm">
          <div className="u-flexColumn u-Width100">
          <div className="u-displayFlex u-Width100 u-justifySpaceBetween">
              <div className="u-displayInlineBlock u-Width100">
                <h3 className="card-graph-title">
                  Tasa de Inflación Anual
                </h3>
                <span className="u-marginHm-12 color-grayed-subtitle">
                  Ultimo año
                </span>
                {/* <div className="u-displayInlineBlock u-alignItems">
                    <svg width="15" height="7">
                        <g>
                          <circle cx="3.5" cy="3.5" r="3.5" fill="#0096D7"></circle>
                        </g>
                      </svg>
                      <span>Dolar Bolsa (MEP)</span>
                      <svg width="15" height="7" className="u-marginLm">
                        <g>
                          <circle cx="3.5" cy="3.5" r="3.5" fill="#4AA785"></circle>
                        </g>
                      </svg>
                    <span>Evolución de Cartera</span>
                </div>                 */}
              </div>          
              <div>
                <CardTitleWSelect
                  customTitle={null}
                  onSelectChange={handleSelectChange}
                />
              </div>              
            </div>  

            <div className="main-chart-container">
              <VerticalBarsAndLineChart
                data={benchmarkData.inflacion.historico}
              />
            </div>
          </div>
        </div>
        <div className="u-displayFlex u-Width95 u-marginBm u-marginLm">
          <div className="base-data-card default-data-card-bg u-Width100">
            <div className="u-flexColumn">
              <h3 className="u-color-title-card card-graph-title">Periodo de 1 AÑO - Inflación</h3>
              <label className="card-price">
                  {benchmarkData.inflacion.variacionAnual}
                  {true ? (
                    <span className="u-color-success">
                      <TrendingUpIcon />
                    </span>
                  ) : (
                    <span className="u-color-danger">
                      <TrendingDownIcon />
                    </span>
                  )}
              </label>
            </div>            
          </div>
          <div className="base-data-card default-data-card-bg u-marginLm u-Width100">
            <div className="u-flexColumn">
              <h3 className="u-color-title-card card-graph-title">Periodo de 1 AÑO - Evolución de Cartera</h3>            
              <label className="card-price">
                  14.5%
                  {true ? (
                    <span className="u-color-success">
                      <TrendingUpIcon />
                    </span>
                  ) : (
                    <span className="u-color-danger">
                      <TrendingDownIcon />
                    </span>
                  )}
              </label>
            </div>            
          </div>
        </div>
      </div>      
    </div>
  );
}
