import React, { useState, useEffect } from "react";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LabelList,
  Cell
} from "recharts";
import dayjs from "dayjs";

const renderCustomLabel = ({ x, y, width, height, value, entry }) => {
  const padding = 5;
  const currentYear = dayjs().year();
  const entryYear = dayjs(entry.mes).year();
  const fillColor = entryYear === currentYear ? "#FAFAFA" : "#000";

  return (
    <text
      x={x + width / 2}
      y={y + height - padding}
      fontFamily="IBM Plex Sans"
      fontWeight="400"
      fontSize="9.41px"
      letterSpacing="0.31px"
      textAnchor="middle"
      fill={fillColor}
      style={{ textTransform: "capitalize" }}
    >
      {value}
    </text>
  );
};

export default function VerticalBarsAndLineChart({ data }) {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 600);
  const currentYear = dayjs().year();

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const formatMonth = (dateString) => {
    const date = dayjs(dateString);
    return date.format("MMM 'YY"); 
  };

  const getBarFill = (entry) => {
    const year = dayjs(entry.mes).year();
    return year === currentYear ? "#214584" : "#d1d2d3";
  };

  return (
    <ResponsiveContainer width="100%" height={isSmallScreen ? 150 : 300}>
      <ComposedChart
        data={data}
        margin={{
          top: 20,
          right: isSmallScreen ? 5 : 10,
          bottom: 20,
          left: isSmallScreen ? 5 : 20,
        }}
      >
        <XAxis
          dataKey="mes"
          axisLine={false}
          tickLine={false}
          tickMargin={15}
          tickFormatter={formatMonth}
        />
        <YAxis axisLine={false} tickLine={false} />
        <Tooltip />

        <Bar
          dataKey="inflacion"
          barSize={isSmallScreen ? 25 : 50}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={getBarFill(entry)} />
          ))}
          <LabelList 
            dataKey="inflacion" 
            content={(props) => renderCustomLabel({ ...props, entry: data[props.index] })}
          />
        </Bar>

        <Line
          type="monotone"
          dataKey="percentage"
          stroke="#4AA785"
          strokeDasharray="5 5"
          dot={false}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}