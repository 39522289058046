import React, { useState, useEffect } from "react";
import "./MainUsuarios.scss";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import SearchIcon from "@mui/icons-material/Search";
import SubSectionOne from "./subSectionOne/subSectionOne";
import SubSectionTwo from "./subSectionTwo/subSectionTwo";
import MobileBottomBar from "../../components/MobileBottomBar/MobileBottomBar";
import MobileTabsMenu from "../../components/MobileTabsMenu/MobileTabsMenu";
import MobileProfileBar from "../../components/MobileProfileCard/MobileProfileBar";
// Models
import { initialPortfolioUserMovementData } from "../../models/PortfolioUserMovementModel";
import { initialProfileDataModel } from "../../models/ProfileDataModel";
import { initialAssetDiversificationModel } from "../../models/AssetDiversificationModel";
import { initialUserOverviewModel } from "../../models/UserOverviewModel";
import { UserModel } from "../../models/UserModel";

// API
import {
  getAssetDiversificationData,
} from "../../services/FinancialServices";
import { 
  getUserPortfolioMovementData, 
  getUserProfileData,
  getUserOverviewData
} from "../../services/FinancialEmployeeService";
import { getUserByCuil } from "../../services/UserStatisticsService";

export default function MainUsuarios({ companyId }) {
  const [cuilNumber, setCuilNumber] = useState("");
  const [isDataLoaded, setIsDataLoaded] = useState(false);
    
  const [portfolioUserMovementData, setPortfolioUserMovementData] = useState(
    initialPortfolioUserMovementData
  );
  
  const [assetDiversification, setAssetDiversification] = useState(
    initialAssetDiversificationModel
  );

  const [profileDataModel, setProfileDataModel] = useState(
    initialProfileDataModel
  );

  const [userOverviewModel, setUserOverviewModel] = useState(
    initialUserOverviewModel
  );

  const [userSearch, setUserSearch] = useState(UserModel);

  useEffect(() => {
    if (companyId && isDataLoaded) {
      
      getAssetDiversificationData(companyId, userSearch?.qpsId).then(
        (response) => {
          const dataResponse = response?.data?.assetDiversificationDTO;
          if (dataResponse) {
            setAssetDiversification(dataResponse);
          }
        }
      );

      getUserPortfolioMovementData(userSearch.id).then((response) => {
        if(response) {
          const dataResponse = response.data;
          if(dataResponse) {
            setPortfolioUserMovementData(dataResponse);
          }
        }
        
      });

      getUserProfileData(userSearch.id).then((response) => {
        if(response) {
          const dataResponse = response.data;
          if(dataResponse) {
            setProfileDataModel(dataResponse);
          }
        }        
      });

      getUserOverviewData(userSearch.id).then((response) => {
        if(response) {
          const dataResponse = response.data.dashboardOverview;
          if(dataResponse) {
            setUserOverviewModel(dataResponse);         
          }
        }        
      });
    }
  }, [companyId, isDataLoaded, userSearch]);

  const handleCuilSearch = () => {
    getUserByCuil(cuilNumber).then((userData) => {
      const dataResponse = userData?.data;      
      if (dataResponse) {
        setUserSearch(dataResponse);
        setIsDataLoaded(true);
      }
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleCuilSearch();
    }
  };

  return (
    <div className="u-displayGrid main-usuarios">
      <div className="usuarios-header display_column">
        <HeaderBar
          headerTitle={"Usuarios"}
          headerTitleInfo={"Resumen del Portfolio por Perfil"}
        />
      </div>

      {!isDataLoaded ? (
        <div className="u-displayFlex u-alignItems u-alignContentCenter cuil-search base-data-card default-data-card-bg ">
          <SearchIcon onClick={handleCuilSearch} />
          <input
            type="text"
            value={cuilNumber}
            onChange={(e) => setCuilNumber(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Ingresa tu número de Cuil"
          />
        </div>
      ) : (
        <div>
          <div className="u-displayFlex display_column wrap web-display">
            <div>
              <SubSectionOne
                companyId={companyId}
                userOverviewModel={userOverviewModel}                
                assetDiversification={assetDiversification}                
                userSearchData={userSearch}
              />
            </div>
            <div>
              <SubSectionTwo
                profileData={profileDataModel}
                portfolioMovements={portfolioUserMovementData}
              />
            </div>
          </div>
          <div className="mobile-display">
            <MobileProfileBar />
            <MobileTabsMenu />
          </div>
        </div>
      )}

      {/*uncomment to test*/}
      <div>
          {/* <div className="u-displayFlex display_column wrap web-display">
            <div>
              <SubSectionOne
                companyId={companyId}
                dasboardOverviewModel={dasboardOverviewModel}
                historicalPerformanceData={historicalPerformanceData}
                assetDiversification={assetDiversification}
              />
            </div>
            <div>
              <SubSectionTwo
                companyId={companyId}
                dasboardOverviewModel={dasboardOverviewModel}
                portfolioMovements={portfolioMovements}
              />
            </div>
          </div> */}

          <div className="mobile-display">
            <MobileProfileBar />
            <MobileTabsMenu />
          </div>
        </div>


      <div className="mobile-display">
        <MobileBottomBar />
      </div>
    </div>
  );
}
